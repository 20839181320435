import React, { useState } from 'react'

// Hooks
import useDomReady from 'src/hooks/useDomReady'

// Components
import Layout from 'src/components/Layout'
import Modal from 'src/components/Modal'
import OpenAccount from 'src/components/Forms/OpenAccount'
import VipList from 'src/components/Forms/VipList'

// PageContext
import pageContext from './pageContext.json'

// Sections
import Hero from './_sections/_hero/_index'
import FreedomDeclaration from './_sections/_freedom-declaration/_index'
import Colections from './_sections/_colections/_index'

const clothes = () => {
  const [openModalAccount, setOpenModalAccount] = useState(false)
  const [openViplist, setOpenViplist] = useState(false)

  const domReady = useDomReady()

  const openAccount = domReady && (
    <Modal isModalOpen={openModalAccount} setIsModalOpen={setOpenModalAccount} locationToRender={document.body}>
      <OpenAccount setIsModalOpen={setOpenModalAccount} setOpenVipModal={setOpenViplist} isModalOpen={openModalAccount}/>
    </Modal>
  )
  const openVipList = domReady && (
    <Modal isModalOpen={openViplist} setIsModalOpen={setOpenViplist} locationToRender={document.body}>
      <VipList setIsModalOpen={setOpenViplist}  />
    </Modal>
  )

  return (
    <Layout pageContext={pageContext}>
      {openAccount}
      {openVipList}
      <Hero />
      <FreedomDeclaration />
      <Colections setOpenModalAccount={setOpenModalAccount} />
    </Layout>
  )
}

export default clothes
