import React from 'react'

// Components
import Modal from 'src/components/Modal'
import OrangeDsIcon from 'src/components/Icon/OrangeDsIcon'

// styles
import { Container, ButtonClose } from './style'

// Types
type ImageZoomProps = {
  open: boolean
  setOpen: Function
  src: string
  alt: string
}

const ImageZoom = ({ open, setOpen, src, alt }: ImageZoomProps) => {
  return(
    <Modal isModalOpen={open} setIsModalOpen={setOpen} locationToRender={document.body}> 
      <Container>
        <ButtonClose onClick={() => setOpen(false)}>
          <OrangeDsIcon icon='exit' size='MD' color='#ff7a00'/>
        </ButtonClose>
        <img src={src} alt={alt} />
      </Container>
    </Modal>
  )
}

export default ImageZoom
