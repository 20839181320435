import styled, { css } from 'styled-components'
import { grayscale, orange } from 'src/styles/colors'

export const TabGroup = styled.ul`
  margin-bottom: 0;
  list-style: none;
  display: inline-flex;
  padding: 0;
`

type TabItemStyleProps = {
  active: boolean;
}

export const TabItem = styled.li<TabItemStyleProps>`
  flex: 0 0 auto;
  
  button {
    border: none;
    border-bottom: 3px solid ${grayscale[100]};
    background: transparent;
    padding: 10px 20px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${grayscale[400]};
    min-width: 17px;
    position: relative;

    ${(props: TabItemStyleProps) => props.active && (
    css`
        color: ${orange.base};
        
        &::after {
          content: '';
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 100%;
          height: 4px;
          background-color: ${orange.base};
          border-radius: 5px 5px 0 0;
        }
      `
  )}

    &:focus {
      outline: 0px;
    }
  }
`

type TabScrollButtonStyleProps = {
  direction: string;
}

export const TabScrollButton = styled.button<TabScrollButtonStyleProps>`
  position: absolute;
  top: 8px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none !important;

  ${(props: TabScrollButtonStyleProps) => {
    switch (props.direction) {
      case 'right':
        return css`
          right: 35px;
        `
      case 'left':
        return css`
          left: 0px;
        `
    }
  }}

  svg {
    fill: ${grayscale[300]};
  }
`
