import React from 'react'

// styles
import { Section } from './style'

const FreedomDeclaration = () => {
  return (
    <Section className='py-5' id='freedom'>
      <div className='container'>
        <div className='row d-flex justify-content-center'>
          <div className='col-12 col-md-8'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-white mb-4'>Seu estado de liberdade</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white mb-3 fw-300'>
              Com a coleção Orange Nation, combinamos os valores que definem o Inter à visão inovadora da Nephew, uma das referências da moda atual. Foram meses de estudo e trabalho com um objetivo: oferecer uma coleção livre, aberta, pronta para receber a todos e todas que queiram fazer parte da Nação Laranja. Usar Orange Nation é se preparar para o novo, para o mundo. Para fazer suas escolhas e aproveitar a vida do seu jeito. Com conforto, estilo e uma certeza: viver pode ser simples.
            </p>
            <h3 className='fs-16 lh-20 fs-lg-20 lh-lg-25 fw-600 text-white'>Essa é a Nação Laranja.</h3>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default FreedomDeclaration
