import React, {useState, useLayoutEffect, useEffect} from 'react'
import axios from 'axios'

// Config
import * as URLS from 'src/config/api/Urls'

// data
import store from 'src/assets/data/orange-nation/store.json'

// hooks
import useWidth from 'src/hooks/useWidth'

// Components
import ZoomImage from 'src/components/ZoomImage'
import TabButton from 'src/components/Tabs/DefaultTab'

// styles
import { ImageButton, Button, Mensage, Title, Link, Installments } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type imageProps = {
  url: string
  alt: string

}

type dataProps = {
  id: string
  image: imageProps
  image_zoom: imageProps
  title: string
  price: string
  price_fmt: string
  installments: string
  mensage: string
  zoomActive?: boolean
  category: string
  venda: boolean
  url: string
}

const WIDTH_MD = 768

type ColectionsProps = {
  setOpenModalAccount: Function
}


const formatPrice = (price: string) => {
  return parseFloat(price).toFixed(2).toString().split('.')
}

const Colections = ({ setOpenModalAccount }: ColectionsProps) => {
  const [zoom, setZoom] = useState(false)
  const [isMobile, setMobile] = useState(true)
  const [ responseData, setResponseData ] = useState<dataProps[]>([])
  const [imagesData, setImagesData] = useState<dataProps[]>([])
  const [categories, setCategories] = useState<string[]>([])
  const [ sendDatalayerEvent ] = useDataLayer()

  const width = useWidth(300)

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(`${URLS.STORE_STATIC}`)
      setResponseData(response.data)
    }

    getData()
  }, [])

  useEffect(() => {
      const newList = responseData.map((item: dataProps) => {
        return {
          ...item,
          zoomActive: false
        }
      })

      setImagesData(newList)
      const listCategories = responseData.map((item: dataProps) => item.category)
      const categoryList = listCategories.filter((item: string, index: number) => listCategories.indexOf(item) === index)

      setCategories(categoryList)
  }, [ responseData ])

  useLayoutEffect(() => {
    setMobile(WIDTH_MD > width)
  }, [width])


  const handleClick = (id: string) => {
    const newlist = imagesData.map((item: dataProps) => {
      if (item.id === id) {
        return {
          ...item,
          zoomActive: true
        }
      } else {
        return {
          ...item,
          zoomActive: false
        }
      }
    })

    setImagesData(newlist)
    setZoom(true)
  }


  return (
    <section className='py-5' id='collection'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 text-center mb-5'>Coleção Orange Nation</h2>
            <TabButton items={categories}>
              {
                categories.map((cat: string, idx: number) => (
                  <div className='row' key={idx}>
                    {
                      imagesData.filter((item: dataProps) => item.category === cat).map((item: dataProps) => (
                        <div className='col-6 col-md-4 col-lg-3 mb-4' key={item.id}>
                          <ImageButton onClick={() => handleClick(item.id)}>
                            <img src={item.image.url} alt={item.image.alt} />
                          </ImageButton>
                          { item.zoomActive &&  <ZoomImage open={zoom} setOpen={setZoom} src={item.image_zoom.url} alt={item.image_zoom.alt}  />}
                          <div className='text-center'>
                            <Title className='fs-14 lh-17 text-grayscale--500 mb-2'>{item.title}</Title>
                            <span className='fs-18 lh-22 text-grayscale--500 fw-700 d-block mb-3'>
                              R$ {formatPrice(item.price.toString())[0]}
                              <sup className='fs-10 lh-12 ml-1'>{formatPrice(item.price.toString())[1]}</sup>
                            </span>
                            <Installments className='fs-12 lh-15 text-grayscale--400'>{item.installments}</Installments>
                            <Mensage className='fs-12 lh-15 text-orange--base d-block mb-0'>{item.mensage}</Mensage>
                            {
                              !item.venda ? (
                                <Link
                                  href={item.url}
                                  onClick={() => {
                                    sendDatalayerEvent({
                                      section: 'dobra_03',
                                      section_name: 'Coleção Orange Nation',
                                      element_action: 'click button',
                                      element_name: 'Ir para loja',
                                      redirect_url: item.url
                                    })
                                  }}
                                >
                                  Ir para loja
                                </Link>
                              ) : (
                              <Button
                                onClick={() => {
                                  setOpenModalAccount(true)
                                  sendDatalayerEvent({
                                    section: 'dobra_03',
                                    section_name: 'Coleção Orange Nation',
                                    element_action: 'click button',
                                    element_name: isMobile ? 'Me avise' : 'Me avise do lançamento',
                                  })
                                }}
                              >
                                {
                                  isMobile ? 'Me avise' : 'Me avise do lançamento'
                                }
                              </Button>
                              )
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
                ))
              }
            </TabButton>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Colections
