import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const ImageButton = styled.button`
  width: 100%;
  height: 120px;
  background: #fff;
  padding: 0;
  border: 1px solid #DEDFE4;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 8px;

  &:focus {
    outline: none;
  }

  @media(min-width: ${breakpoints.md}) {
    height: 160px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 12px;
  }


`
export const Mensage = styled.div`
    min-height:30px ;

`
export const Title = styled.p`
  min-height: 34px;
  height:68px ;
`
export const Button = styled.button`
  width: 100%;
  height: 48px;
  background: #FF7A00;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-top: 16px;

  &:focus {
    outline: none;
  }
`
export const Link = styled.a`
    width: 100%;
    height: 48px;
    background: #fff;
    border: 1px solid #ff7a00;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #ff7a00;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top: 16px;

    &:focus, &:hover {
    outline: none;
    text-decoration:none;
    color: #ff7a00;
  }

`
export const Installments = styled.div`
    height:30px;


`
