import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'

export const Container = styled.div`
  width: 312px;
  height: 190px;
  background: ${white};
  border: 1px solid ${grayscale[200]};
  border-radius: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  overflow: hidden;

  @media (min-width: ${breakpoints.md}) {
    width: 696px;
    height: 422px;
  }
  
  @media (min-width: ${breakpoints.lg}) {
    width: 700px;
    height: 424px;
  }

  @media (min-width: ${breakpoints.xl}) {
    width: 1000px;
    height: 606px;
  }

  img { 
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
export const ButtonClose = styled.button`
  top: 10px;
  right: 5px;
  position: absolute;
  border: none;
  background: none;

  @media (min-width: ${breakpoints.md}) {
    top: 24px;
    right: 24px;
  }

  &:focus{
    outline: none;
  }
`