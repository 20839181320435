import React, { ReactNode, useState } from 'react'
import useDomReady from 'src/hooks/useDomReady'

import YoutubeVideo from '../YoutubeVideo'
import Modal from 'src/components/Modal'

import {ModalContent} from './style'

type OpenVideoProps = {
  link: string;
  children: ReactNode;
}
function OpenVideo ({ children, link }: OpenVideoProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const domReady = useDomReady()

  function handleClose () {
    setIsModalOpen(false)
  }

  function handleOpen () {
    setIsModalOpen(true)
  }

  return (
    <>
      <div onClick={handleOpen}>
        {children}
      </div>
      {
        domReady && (
          <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
            <ModalContent>
              <div className='backdrop' onClick={handleClose} />
              <div className='content'>
                <div className='close-button' onClick={handleClose}>X</div>
                <YoutubeVideo
                  src={link}
                  width='100%'
                  height='100%'
                />
              </div>
            </ModalContent>
          </Modal>
        )
      }
    </>
  )
}

OpenVideo.defaultProps = {
  link: '',
  children: <></>,
}

export default OpenVideo
