import styled from "styled-components"
import { breakpoints } from 'src/styles/breakpoints'

// imagens
import bgXs from '../../assets/images/heroBgXs.gif'
import bgMd from '../../assets/images/heroBgMd.gif'
import bgLg from '../../assets/images/heroBgLg.gif'
import bgXl from '../../assets/images/heroBgXl.gif'

export const Section = styled.section`
  background: url(${bgXs});
  min-height: 500px;
  background-size: 100%;
  background-repeat: no-repeat;

  @media(min-width: ${breakpoints.md}) {
    background: url(${bgMd});
    min-height: 500px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  @media(min-width: ${breakpoints.lg}) {
    background: url(${bgLg});
    min-height: 600px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  @media(min-width: ${breakpoints.xl}) {
    background: url(${bgXl});
    min-height: 768px;
    background-size: cover;
    background-repeat: no-repeat;
  }
`

export const PlayVideo = styled.div`
  width: 82px;
  height: 82px;
  cursor: pointer;
  text-align: center;
`
