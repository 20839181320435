/* eslint-disable react/jsx-closing-tag-location */
import React, { ReactNode, useEffect, useRef, useState, useLayoutEffect } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import ChevronLeft from 'inter-frontend-svgs/lib/v2/navigation/chevron-left'
import ChevronRight from 'inter-frontend-svgs/lib/v2/navigation/chevron-right'

import { TabGroup, TabItem, TabScrollButton } from './style'

type ScrollDistanceProps = {
  [param: string]: number;
  right: number;
  left: number;
}

type DefaultTabProps = {
  className?: string;
  items: string[];
  children: ReactNode[];
}


const DefaultTab = ({ className, items, children }: DefaultTabProps) => {
  const scrollContainerRef = useRef<HTMLElement>(null)
  const tabGroupRef = useRef<HTMLUListElement>(null)
  const scrollDistance: ScrollDistanceProps = { right: 200, left: -200 }
  const [ showArrows, setShowArrows ] = useState(false)
  const [ activeTab, setActiveTab ] = useState(0)
  const [ scrollLeft, setScrollLeft ] = useState(0)

  useEffect(() => {
    if (scrollContainerRef.current && tabGroupRef.current) {
      setShowArrows(scrollContainerRef.current.offsetWidth <= tabGroupRef.current.offsetWidth)
    }
  }, [ scrollContainerRef, tabGroupRef ])

  function handleClick (index: number) {
    setActiveTab(index)
  }

  function handleClickScroll (direction: string) {
    if (scrollContainerRef.current) {
      const newScrollLeft = scrollLeft + scrollDistance[direction]
      setScrollLeft(newScrollLeft)
      scrollContainerRef.current.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
      })
    }
  }

  function handleScroll (scrollLeft: number) {
    setScrollLeft(scrollLeft)
  }

  return (
    <div className={`position-relative w-100 col-md-12 ${className}`}>
      {
        showArrows && (
          <>
            <TabScrollButton onClick={() => handleClickScroll('left')} direction='left'>
              <ChevronLeft widht={24} height={24} />
            </TabScrollButton>
            <TabScrollButton onClick={() => handleClickScroll('right')} direction='right'>
              <ChevronRight widht={24} height={24} />
            </TabScrollButton>
          </>
        )
      }
      <ScrollContainer
        innerRef={scrollContainerRef}
        component='nav'
        onScroll={handleScroll}
        style={{
          maxWidth: showArrows ? 'calc(100% - 108px)' : '100%',
          marginLeft: showArrows ? '36px' : '0',
          marginBottom: '40px',
          borderBottom: '1px solid #DEDFE4'
        }}
      >
        <TabGroup ref={tabGroupRef}>
          {items.map((item: string, index: number) => (
            <TabItem active={activeTab === index} key={index}>
              <button onClick={() => handleClick(index)}>{item}</button>
            </TabItem>
          ))}
        </TabGroup>
      </ScrollContainer>
      <div>
        {
          children.map((item: ReactNode, index: number) => (
            <div className={`${activeTab === index ? 'd-block' : 'd-none'}`} key={index}>{item}</div>
          ))
        }
      </div>
    </div>
  )
}

export default DefaultTab
