import React from 'react'

// styles
import { Section, PlayVideo } from './style'
import playCollection from '../../assets/images/playCollection.png'

// Page Query
import OpenVideo from 'src/components/OpenVideo'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const Hero = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 d-flex align-items-center' id='hero'>
      <div className='container'>
        <div className='row'>
          <div
            className='col-12 d-flex justify-content-center'
          >
            <OpenVideo link='https://www.youtube.com/embed/Fr49piP9DA0'>
              <PlayVideo
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'null',
                    element_action: 'click video',
                    element_name: 'Assista ao vídeo',
                    redirect_url: 'https://www.youtube.com/embed/Fr49piP9DA0'
                  })
                }}
              >
                <img src={playCollection} />
                <p className='fs-16 lh-20 text-orange--base mt-3 fw-600'>
                  <span className='d-block'>Assista</span> ao vídeo
                </p>
              </PlayVideo>
            </OpenVideo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
